.wh-form__buttongroup {
  text-align: right;
  padding: 10px 0 0;
}
.wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.wh-form__buttongroup .wh-form__button {
  margin-bottom: 10px;
}
.wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}

.wh-page .wh-form__buttongroup {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 2px solid #13A538;
}

#classportal_registerform .wh-form__buttongroup {
  margin-top: 0;
  padding-top: 15px;
  border-top: 0 none;
}

.button, .wh-form__button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-decoration: none;
  font: 700 20px/24px "TypoPRO Dosis", "Dosis", Arial;
  min-width: 200px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  height: 48px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 7px 15px 9px;
  position: relative;
  background-color: var(--color-green);
  color: #fff;
  text-align: left;
  transition: opacity 0.2s;
  transition: background-color 0.3s;
  box-shadow: none;
  -webkit-appearance: none;
  margin: 0;
  border: 0 none;
}
.button--fullwidth, .wh-form__button--fullwidth {
  width: 100%;
}
.button--centercontent, .wh-form__button--centercontent {
  justify-content: center;
}
.button:hover, .wh-form__button:hover {
  opacity: 0.8;
}
.button .wh-form__buttonlabel, .button__label, .wh-form__button .wh-form__buttonlabel, .wh-form__button__label {
  position: relative;
  display: block;
}
.button::after, .wh-form__button::after {
  flex: none;
  padding-top: 2px;
  padding-left: 15px;
  font-weight: 700;
  display: inline-block;
  content: "\f054";
  font-size: 17px;
  font-family: "Font Awesome 5 Pro";
}
.button--search::after, .wh-form__button--search::after {
  font-size: 18px;
  content: "\f002";
}

.button--text {
  font-size: 18px;
  justify-content: flex-start;
  display: inline-flex;
  background-color: transparent;
  border: 0 none;
  color: var(--color-green-dark);
  padding: 0;
}

.button--text .button__label {
  display: inline-block;
  flex: none;
  text-decoration: underline;
  text-underline-position: under;
}

.button--outline {
  background-color: #fff;
  color: var(--color-green-dark);
  border: 1px solid var(--color-green-dark);
  transition: background-color 0.2s;
}
.button--outline:hover {
  background-color: #f6f6f6;
}

.contentcols__col + .contentcols__col .button,
.button--small {
  height: 40px;
}

.wh-form__button--previous, .button--previous,
.wh-form__button--cancel, .button--cancel {
  float: left;
  border: 1px solid var(--color-green-dark);
  background-color: #fff;
  color: var(--color-green);
  transition: background-color 0.2s;
}
.wh-form__button--previous:hover, .button--previous:hover,
.wh-form__button--cancel:hover, .button--cancel:hover {
  background-color: #f6f6f6;
}
.wh-form__button--previous::after, .button--previous::after,
.wh-form__button--cancel::after, .button--cancel::after {
  order: -1;
  padding-right: 15px;
  padding-left: 0;
  content: "\f104";
}
.wh-form__button--previous.wh-form__button--cancel, .wh-form__button--previous.button--cancel, .button--previous.wh-form__button--cancel, .button--previous.button--cancel,
.wh-form__button--cancel.wh-form__button--cancel,
.wh-form__button--cancel.button--cancel, .button--cancel.wh-form__button--cancel, .button--cancel.button--cancel {
  float: none;
  vertical-align: bottom;
  min-width: 80px;
}
.wh-form__button--previous.wh-form__button--cancel::after, .wh-form__button--previous.button--cancel::after, .button--previous.wh-form__button--cancel::after, .button--previous.button--cancel::after,
.wh-form__button--cancel.wh-form__button--cancel::after,
.wh-form__button--cancel.button--cancel::after, .button--cancel.wh-form__button--cancel::after, .button--cancel.button--cancel::after {
  content: "";
  display: none;
}
.wh-form__button--previous .wh-form__buttonlabel, .wh-form__button--previous .button__label, .button--previous .wh-form__buttonlabel, .button--previous .button__label,
.wh-form__button--cancel .wh-form__buttonlabel,
.wh-form__button--cancel .button__label, .button--cancel .wh-form__buttonlabel, .button--cancel .button__label {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}