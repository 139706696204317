.datetime {
  position: relative;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  flex: none;
  padding: 0 10px;
}
.datetime--replaced {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
.datetime--disabled {
  background-color: #F2F2F2 !important;
  border-color: #C5C5C5 !important;
}
.datetime__sep {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
}
.datetime--disabled .datetime__sep {
  color: #666;
}
.datetime__part {
  background: transparent;
  border: 0;
  min-width: 29px;
  width: calc(2ch + 10px);
  text-align: center;
  font: inherit;
  padding: 0;
  margin: 0;
  box-shadow: none !important;
  border: 0 none !important;
  padding: 0 !important;
  max-height: 41px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}
.datetime__part::-webkit-outer-spin-button, .datetime__part::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.datetime__year {
  min-width: 45px;
  width: calc(4ch + 8px);
  text-align: left;
}
.datetime__year::-webkit-input-placeholder {
  letter-spacing: 3px;
}
.datetime__year::-moz-placeholder {
  letter-spacing: 3px;
}
.datetime__year:-ms-input-placeholder {
  letter-spacing: 3px;
}
.datetime__year:-moz-placeholder {
  letter-spacing: 3px;
}
.datetime__msec {
  min-width: 36px;
  width: calc(3ch + 8px);
}
.datetime__controls {
  margin-left: 5px;
  color: var(--color-purple);
}
.datetime__controls > span {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
}
.datetime--disabled .datetime__controls {
  opacity: 0.3;
}
.datetime__togglepicker {
  vertical-align: baseline;
}
.datetime__togglepicker::before {
  content: "\f073";
  font-size: 18px;
}
.datetime__reset {
  vertical-align: middle;
}
.datetime__reset::before {
  content: "\f00d";
}
.datetime__picker {
  z-index: 2;
  position: fixed;
  border: 2px solid var(--color-green);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px 7px rgba(0, 0, 0, 0.1);
}
.datetime__picker__previous, .datetime__picker__next {
  display: none;
}
.datetime__picker select, .datetime__picker input {
  height: 24px;
  font-size: inherit;
}
.datetime__picker input {
  padding-left: 10px;
}
.datetime__picker__weekday {
  padding: 5px 5px 3px;
}
.datetime__picker__day {
  cursor: pointer;
  outline: 0 none;
  padding: 0 5px;
}
.datetime__picker__day span {
  border-radius: 5px;
  display: block;
  padding: 5px 5px 5px 5px;
  text-align: right;
  border: 1px solid #fff;
  transition: border-color 0.2s;
}
.datetime__picker__day--selected > span {
  background-color: var(--color-green);
  color: #fff;
}
.datetime__picker__day:hover > span, .datetime__picker__day:focus > span {
  border-color: var(--color-purple);
}
.datetime__picker__header {
  background-color: var(--color-green);
  padding: 3px;
  display: flex;
  justify-content: space-between;
}