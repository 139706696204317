.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

:root {
  --wh-field-textinput-height: 48px;
  --wh-field-textarea-height: 140px;
  --wh-field-radiocheckbox-size: 24px;
  --wh-label-font-size: 14px;
  --wh-label-width: auto;
  --wh-button-background-color: var(--color-green);
  --wh-button-border: 0 none;
  --wh-button-color: #fff;
  --wh-button-padding: 12px clamp(10px, 2dvw, 20px);
  --wh-button-border-radius: 5px;
  --wh-button-height: 48px;
  --wh-field-font-size: 16px;
  --wh-field-padding: 12px 15px;
  --wh-field-border-radius: 5px;
  --wh-field-color: inherit;
  --wh-field-color--hover: inherit;
  --wh-field-color--focus: inherit;
  --wh-field-color--error: var(--color-red);
  --wh-field-color--disabled: #C5C5C5;
  --wh-field-border-color: var(--color-grey-fieldborder);
  --wh-field-border-color--hover: var(--color-purple);
  --wh-field-border-color--focus: var(--color-purple);
  --wh-field-border-color--error: var(--color-red);
  --wh-field-border-color--disabled: #C5C5C5;
  --wh-field-background-color: #fff;
  --wh-field-background-color--hover: #fff;
  --wh-field-background-color--focus: #fff;
  --wh-field-background-color--error: #fff;
  --wh-field-background-color--disabled: #F2F2F2;
  --wh-field-border-box-shadow: none;
  --wh-field-border-box-shadow--hover: 0 0 0 3px rgba(100, 28, 118, 0.15);
  --wh-field-border-box-shadow--focus: 0 0 0 3px rgba(100, 28, 118, 0.15);
  --wh-field-border-box-shadow--error: none;
  --wh-field-border-box-shadow--disabled: none;
  --wh-imgedit-padding: 4px;
  --wh-imgedit-margin: 2px 3px;
  --wh-imgedit-width: 150px;
  --wh-imgedit-height: 200px;
  --wh-imgedit-explain-padding: 5px;
  --wh-imgedit-uploadicon-width: 50px;
  --wh-imgedit-uploadicon-height: 50px;
  --wh-imgedit-uploadicon-margin: 0 0 20px;
  --wh-fileedit-name-margin-right: 15px;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: var(--color-grey-placeholder);
}

::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey-placeholder);
}

:-ms-input-placeholder {
  opacity: 1;
  color: var(--color-grey-placeholder);
}

input:-moz-placeholder {
  opacity: 1;
  color: var(--color-grey-placeholder);
}

input:disabled::-webkit-input-placeholder {
  opacity: 0.8;
}

input:disabled::-moz-placeholder {
  opacity: 0.8;
}

input:disabled:-ms-input-placeholder {
  opacity: 0.8;
}

input:disabledinput:-moz-placeholder {
  opacity: 0.8;
}

.wh-form__prefillarea {
  position: absolute;
  min-width: 200px;
  opacity: 0.85;
  transition: opacity 200ms, transform 200ms;
  z-index: 1;
  font-size: 13px;
  transform-origin: 0 0;
  transform: scale(0.4, 0.4);
}
.wh-form__prefillarea select {
  font-size: inherit !important;
}
.wh-form__prefillarea:hover {
  opacity: 1;
  transform: scale(1, 1);
}

.wh_form__requirednote {
  border-radius: 9px;
  background-color: var(--color-green-light);
  padding: 15px;
  margin-bottom: 24px;
}
.wh_form__requirednote__icon {
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 5px;
  font-size: 20px;
  color: var(--color-purple);
}

h2.wh_form__fieldgroup__header {
  color: var(--color-purple);
}

.wh-form__page--visible[hidden] {
  display: block;
}

.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

/* infotext below input */
.wh-form__infotextwrapper {
  width: 100%;
  outline: none;
}
.wh-form__infotextwrapper p {
  margin-bottom: 10px;
}
.wh-form__infotextwrapper .wh-form__infotext {
  font-size: 14px;
  background-color: #F5F5F5;
  border-radius: 0 0 5px 5px;
  padding: 10px 15px;
  width: 100%;
}
.wh-form__infotextwrapper .wh-form__infotext > *:last-child {
  margin-bottom: 0;
}

.wh-form__fieldline--nowrap.wh-form__fieldline--hasinfotext {
  flex-wrap: wrap;
}

.wh-form__optiondata + .wh-form__infotextwrapper {
  margin-top: 10px;
  flex: 0 0 100%;
}

.wh-styledinput {
  font-size: inherit;
}
.wh-styledinput a:not(.wh-form__button) {
  color: var(--color-green-dark);
  text-decoration: none;
}
.wh-styledinput a:not(.wh-form__button):hover {
  text-decoration: underline;
}
.wh-styledinput .wh-anchor {
  scroll-margin-top: 120px;
}
.wh-styledinput hr {
  display: block;
  border: 0 none;
  border-top: 1px solid var(--color-grey-lines);
  margin: 30px 0 25px;
}
.wh-styledinput h2.wh-form__grouptitle {
  border-bottom: 0 none;
  padding-bottom: 0;
  font-size: 20px;
}
@media (max-width: 356px) {
  .wh-styledinput h2.wh-form__grouptitle {
    font-size: 18px;
  }
}
.wh-styledinput .wh-form__fieldgroup {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  position: relative;
}
.wh-styledinput .wh-form__fieldgroup--heading {
  margin-bottom: 0;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  padding-bottom: 3px;
  padding-right: 0;
}
.wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
  flex-wrap: wrap;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  flex: none !important;
  max-width: calc(100vw - 40px) !important;
  min-width: 0 !important;
}
.wh-styledinput .wh-form__fields {
  width: 100%;
}
.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after {
  display: inline-block;
  content: "*";
}
.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
  font-size: 14px;
}
.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
  font-size: 14px;
}
.wh-styledinput .wh-form__optionlabel, .wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
  font-size: 16px;
  line-height: 140%;
}
.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: none;
  max-width: none;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-purple);
}
.wh-styledinput .wh-form__fieldgroup--textedit, .wh-styledinput .wh-form__fieldgroup--textarea,
.wh-styledinput .wh-form__fieldgroup--pulldown,
.wh-styledinput .wh-form__fieldgroup--upload,
.wh-styledinput .wh-form__fieldgroup--date,
.wh-styledinput .wh-form__fieldgroup--time {
  padding-top: 10px;
}
.wh-styledinput .wh-form__fieldgroup--textedit > .wh-form__label, .wh-styledinput .wh-form__fieldgroup--textarea > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--pulldown > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--upload > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--date > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--time > .wh-form__label {
  max-width: calc(100% - 10px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: -4px;
  left: 10px;
  z-index: 1;
  padding: 0 5px;
  height: 20px;
  transition: top 200ms;
  background-color: #fff;
  /*
  if background-color used, no mask is needed
        &::before  //masking border input under label
        {
          content: "";
          position: absolute;
          top: 14px;
          left: 0;
          right: 0;
          height: 3px;
          display: block;
          background-color: #fff;
          z-index: -1;
        }
  */
}
.wh-styledinput .wh-form__fieldgroup--textedit.wh-form__fieldgroup--label-in-field > .wh-form__label, .wh-styledinput .wh-form__fieldgroup--textarea.wh-form__fieldgroup--label-in-field > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--pulldown.wh-form__fieldgroup--label-in-field > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--upload.wh-form__fieldgroup--label-in-field > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--date.wh-form__fieldgroup--label-in-field > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--time.wh-form__fieldgroup--label-in-field > .wh-form__label {
  top: 27px;
  font-size: 16px;
  color: var(--color-black);
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label:empty,
.wh-styledinput .wh-form__fieldgroup > .wh-form__label[hidden] {
  display: none;
}
.wh-styledinput .wh-form__fields, .wh-styledinput .wh-form__label {
  font-size: 16px;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields .wh-form__fieldline {
  display: block;
}
.wh-styledinput .wh-form__fieldgroup.labelabove {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
}
.wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__label {
  padding-bottom: 10px;
}
.wh-styledinput .wh-form__fieldgroup.labelabove .wh-form__subfield {
  flex-wrap: wrap;
}
.wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__label, .wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__fields {
  flex: none;
  max-width: calc(100vw - 40px);
  min-width: 0;
}
.wh-styledinput .wh-form__fieldgroup.labelabove .wh-form__fields {
  width: 100%;
}
.wh-styledinput select + .wh-form__subfield > label,
.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea {
  min-height: 48px;
  padding: 14px 15px;
  flex: 1;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea,
.wh-styledinput .wh-form__imgedit,
.wh-styledinput .datetime {
  border: 1px solid var(--color-grey-fieldborder);
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  max-width: 100%;
  color: inherit;
  border-radius: 5px;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-styledinput input:disabled, .wh-styledinput input[data-wh-form-disabled], .wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-styledinput textarea:disabled,
.wh-styledinput textarea[data-wh-form-disabled],
.wh-styledinput .wh-form__imgedit:disabled,
.wh-styledinput .wh-form__imgedit[data-wh-form-disabled],
.wh-styledinput .datetime:disabled,
.wh-styledinput .datetime[data-wh-form-disabled] {
  color: #666;
  border-color: #C5C5C5;
  background-color: #F2F2F2;
  box-shadow: none;
}
.wh-styledinput input:focus-within, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus-within,
.wh-styledinput textarea:focus-within,
.wh-styledinput .wh-form__imgedit:focus-within,
.wh-styledinput .datetime:focus-within {
  border-color: var(--color-purple);
  box-shadow: 0 0 0 3px rgba(100, 28, 118, 0.15);
}
.wh-styledinput input:focus, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus,
.wh-styledinput textarea:focus,
.wh-styledinput .wh-form__imgedit:focus,
.wh-styledinput .datetime:focus {
  border-color: var(--color-purple);
  box-shadow: 0 0 0 3px rgba(100, 28, 118, 0.15);
}
.wh-styledinput textarea {
  min-height: 140px;
}
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline input, .wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline select,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline textarea,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline .wh-form__imgedit,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline .datetime {
  border-color: var(--color-red);
}
.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  font-weight: bold;
  width: 100%; /* force error text below item */
  color: var(--color-red);
  padding: 8px 15px 0;
  font-size: 14px;
}
.wh-styledinput .wh-form__error::before {
  content: "\f071";
  display: inline-block;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 7px;
}
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__error,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__error {
  display: block;
}