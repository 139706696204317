@import "/.publisher/sd/minfin_gastlessen/gastlessen2022/fonts/fontawesome/css/all.min.css";
@import "/.minfin_wig/fonts/@typopro/web-dosis/typopro-dosis.css";

html
{
  font: 16px/130% Arial, Helvetica, Sans-Serif;
  color: #333;
}

h1, h2, h3
{
  font-family: "TypoPRO Dosis", "Dosis", Arial;
  line-height: 130%;
  font-weight: bold;
  color: #13A538;
  margin-bottom: 10px;
}
h1
{
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 20px;
}
h2
{
  color: #1E7B26;
  font-size: 20px;
}
h3, h2.small
{
  font-size: 17px;
  color: #641C76;
}
h4, h3.small, h2.smaller
{
  font-size: inherit;
  color: #641C76;
}
h4
{
  margin-bottom: 0;
}

p a, .unordered a, .ordered a, .wh-rtd__table a, .wh-form a:not(.wh-form__button)
{
  color: #1E7B26;
  text-underline-position: under;
}
p a:hover, .unordered a:hover, .ordered a:hover, .wh-rtd__table a:hover, .wh-form a:hover
{
  text-shadow: 0 0 1px #1E7B26;
}
p
{
  line-height: 160%;
}

p, .widget
{
  margin-bottom: 15px;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
, ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin: 0;
  padding: 0 0 0 10px;
  line-height: 160%;
  list-style-type: none;
}
  ul.unordered
, ol.ordered
{
  margin-bottom: 20px;
}
  p + ul.unordered
, p + ol.ordered
{
  margin-top: -15px;
}
  article * ul.unordered
, article * ol.ordered
{
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}
  ul.unordered li
, ol.ordered li
{
  position: relative;
  padding: 0 0 0 20px;
  min-height: 15px;
}
  ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
  ul.unordered li::before
, ol.ordered li::before
{
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  color: #13A538;
  font-weight: bold;
}
  ul.unordered > li
, ol.ordered ul > li
, ul.unordered ul > li
{
  padding-left: 15px;
  padding-bottom: 5px;
  min-height: 15px;
}
  ul.unordered > li::before
, ol.ordered ul > li::before
, ul.unordered ul > li::before
{
  padding-top: 0;
  content: '•';
  text-align: center;
}
  ol.ordered
, ul.unordered ol
, ol.ordered ol
{
  counter-reset: li;
}
  ol.ordered > li::before
, ul.unordered ol > li::before
, ol.ordered ol > li::before
{
  content: counter(li) ".";
  counter-increment: li;
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
  border-bottom: solid 1px #b3b3b3;
}

article > .wh-rtd__tablewrap
{
  max-width: 790px;
}
table
{
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}
table td, table th
{
  vertical-align: top;
  text-align: left;
}

.wh-rtd__table td, .wh-rtd__table th
{
  padding: 5px 10px;
}
  table td > *:last-child
, table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #1a7dd7;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #1a7dd7;
}
