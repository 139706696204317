.footer-logos {
  padding: 20px 0 0;
}
.footer-logos__wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.footer-logos__logowvg, .footer-logos__logowig {
  display: block;
  flex: none;
  text-decoration: none;
}
.footer-logos__logowvg {
  height: 108px;
  flex: none;
}
.footer-logos__logowvg img {
  height: 100%;
  width: auto;
  vertical-align: bottom;
}
.footer-logos__logowig {
  text-align: right;
  font-weight: 500;
  color: #767676;
  font-size: 14px;
  line-height: 15px;
  padding-bottom: 20px;
}
.footer-logos__logowig img {
  height: 22px;
  width: auto;
  vertical-align: bottom;
}
@media (max-width: 771px) {
  .footer-logos__logowvg {
    height: 14vw;
  }
  .footer-logos__logowig {
    padding-bottom: 2.6vw;
  }
}
@media (max-width: 550px) {
  .footer-logos__logowvg {
    height: 77px;
  }
  .footer-logos__logowig {
    padding-bottom: 14px;
  }
  .footer-logos__logowig img {
    height: 4vw;
  }
}
@media (max-width: 450px) {
  .footer-logos__logowig img {
    height: 18px;
  }
}

.footer-bar {
  color: #fff;
  padding: 20px 0;
}
.footer-bar__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-bar__date {
  font-weight: bold;
  font-size: clamp(18px, 3.1dvw, 24px);
}
.footer-bar__socialmedia {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 700;
  font-family: "TypoPRO Dosis Menu", "TypoPRO Dosis", "Dosis", Arial;
}
.footer-bar__socialmedia a {
  background-color: #fff;
  text-decoration: none;
  margin-left: 13px;
  display: block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border-radius: 50%;
  color: var(--color-green);
}
.footer-bar__socialmedia a:hover {
  text-shadow: 0 0 1px var(--color-green);
}
@media (max-width: 760px) {
  .footer-bar__socialmedia a {
    width: 5vw;
    height: 5vw;
    line-height: 5vw;
    font-size: 2.6vw;
  }
}
@media (max-width: 550px) {
  .footer-bar__socialmedia a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
}
@media (max-width: 450px) {
  .footer-bar__socialmedia__title {
    display: none;
  }
}

.footer-nav {
  padding: 15px 0;
  font-size: 14px;
}
.footer-nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer-nav__copyright {
  margin-right: 15px;
}
.footer-nav__nav {
  margin-left: -15px;
}
.footer-nav__nav__link {
  text-decoration: none;
  text-underline-position: under;
  color: inherit;
  display: inline-block;
  margin-left: 15px;
}
.footer-nav__nav__link:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .footer-nav__wrapper {
    display: block;
  }
  .footer-nav__nav {
    margin-top: 5px;
  }
}