.widget-circlegraph {
  margin-bottom: 24px;
  background-color: #fff;
  padding: 20px 24px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #D2D2D2;
  border-radius: 8px;
}
.widget-circlegraph__inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.widget-circlegraph a {
  text-decoration: underline;
  color: var(--color-purple);
}
.widget-circlegraph--ready svg circle {
  transition: all 300ms linear;
}
.widget-circlegraph svg {
  flex: none;
  width: 132px;
  height: 132px;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
}
.widget-circlegraph svg circle {
  stroke-width: 26px;
  stroke: #13A538;
  transform-origin: 50% 50%;
}
.widget-circlegraph svg circle + circle {
  stroke: #C20A5B;
}
.widget-circlegraph svg circle + circle + circle {
  stroke: var(--color-purple);
}
.widget-circlegraph__legenda {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: 20px;
  font-size: 14px;
  color: #767676;
}
.widget-circlegraph__legenda strong {
  font-size: 120%;
  display: block;
  padding-bottom: 3px;
  color: #13A538;
  font-size: 20px;
}
.widget-circlegraph__legenda li {
  padding: 5px 0;
}
.widget-circlegraph__legenda li + li strong {
  color: #C20A5B;
}
.widget-circlegraph__legenda li + li + li strong {
  color: var(--color-purple);
}
.widget-circlegraph__categories {
  margin-left: -10px;
  margin-top: 15px;
}
.widget-circlegraph__categories__button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  padding: 0 10px;
  line-height: 24px;
  border-radius: 12px;
}
.widget-circlegraph__categories__button--active {
  color: #fff;
  background-color: var(--color-purple);
}
.widget-circlegraph__description {
  align-self: flex-start;
  margin-left: 24px;
}