.widget-downloads__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #C5C5C5;
}
.widget-downloads__item {
  border-bottom: 1px solid #C5C5C5;
}
.widget-downloads__item__link {
  display: flex;
  text-decoration: none;
  align-items: flex-start;
  justify-content: flex-start;
  color: inherit;
  padding: 15px 0;
  text-underline-position: under;
  line-height: 135%;
}
.widget-downloads__item__icon {
  flex: none;
  font-size: 24px;
  margin-right: 15px;
  color: var(--color-green);
}
.widget-downloads__item__details {
  flex: 1;
}
.widget-downloads__item__title {
  font-weight: 700;
}
.widget-downloads__item__description {
  font-size: 90%;
  color: #666;
}
.widget-downloads__item__link:hover .widget-downloads__item__title {
  text-decoration: underline;
}
.widget-downloads__item__filesize {
  font-size: 80%;
  color: var(--color-green-dark);
}