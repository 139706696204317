.autocomplete {
  background: #f8f8f8;
  z-index: 1000;
  overflow: auto;
  margin-top: -1px;
  box-sizing: border-box;
  border: 1px solid #641C76;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font: inherit;
  font-size: 95%;
}
.autocomplete > div {
  padding: 5px 15px;
  cursor: pointer;
}
.autocomplete > div.selected, .autocomplete > div:hover {
  background: rgba(100, 28, 118, 0.05);
}