.widget-searchschool .feedbacknote {
  display: block;
  font-size: 85%;
  margin-top: 10px;
}
.widget-searchschool__form {
  padding-top: 0;
}
.widget-searchschool__form .wh-form__fieldgroup--checkboxslider {
  padding-top: 5px;
  align-items: flex-start;
  justify-content: center;
}
.widget-searchschool__form .wh-form__fieldgroup--checkboxslider .wh-form__optiondata--horizontal {
  width: auto;
}
.widget-searchschool__form .wh-form__fieldgroup--checkboxslider .wh-form__fields {
  flex-wrap: nowrap;
  align-items: center;
}
.widget-searchschool__form .wh-form__fieldgroup--checkboxslider .wh-form__optionlabel {
  padding-right: 0;
  text-transform: uppercase;
}
.widget-searchschool__form .wh-form__fieldgroup--checkboxslider .wh-form__fieldline--nowrap {
  margin-top: 0px;
}
.widget-searchschool__form .infopanel {
  margin: -15px -15px 15px;
  position: relative;
}
.widget-searchschool__form .infopanel input {
  width: 100%;
}
.widget-searchschool__form .wh-form__button {
  flex: none;
  min-width: 120px;
  margin-top: 20px;
}
.widget-searchschool__form .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.widget-searchschool__form .row__group {
  display: flex;
  margin: 0 -24px;
  flex-wrap: wrap;
  flex: 1 1 auto;
  align-items: stretch;
  max-width: calc(100% - 120px);
}
.widget-searchschool__form .row__group > * {
  margin-left: 24px;
  margin-bottom: 0;
  margin-top: 20px;
}
.widget-searchschool__form .row__group input[name=location] {
  flex: none;
  width: 242px;
}
@media (max-width: 768px) {
  .widget-searchschool__form .row {
    flex-flow: column;
    align-items: flex-start;
  }
  .widget-searchschool__form .row__group {
    max-width: 100%;
  }
  .widget-searchschool__form .row .wh-form__button {
    align-self: flex-end;
  }
}
.widget-searchschool__feedback {
  margin-bottom: 25px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-searchschool__feedback__count {
  font-weight: 600;
  font-size: 20px;
}
.widget-searchschool__feedback .error {
  padding-top: 10px;
  color: var(--color-red);
}
.widget-searchschool__feedback .loadingindicator {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 100%;
  font-size: 20px;
  margin-bottom: 4px;
  margin-left: -10px;
  text-align: center;
}
.widget-searchschool__feedback.loading .loadingindicator {
  display: block;
}
.widget-searchschool__filterstoggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: 500 18px/28px "TypoPRO Dosis", "Dosis", Arial;
  border-radius: 5px;
  border: 1px solid #929292;
  height: 48px;
  display: inline-flex;
  padding: 10px 15px;
  color: var(--color-green-dark);
  align-self: flex-end;
  align-items: center;
}
.widget-searchschool__filterstoggle i {
  padding-left: 10px;
}
.widget-searchschool__advancedfilters {
  position: absolute;
  left: 0;
  right: 0;
  top: 108px;
  overflow: hidden;
  height: 0;
  transition: height 300ms;
  background-color: #fff;
  z-index: 1;
}
.widget-searchschool__advancedfilters__inner {
  height: 100%;
}
.widget-searchschool__advancedfilters__close {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: 700 20px/24px "TypoPRO Dosis Buttons", "Dosis", Arial;
  display: inline-flex;
  padding: 10px 15px;
  align-items: center;
}
.widget-searchschool__advancedfilters__close i {
  font-size: 24px;
  padding-left: 10px;
}
html.showadvancedfilters .widget-searchschool__advancedfilters {
  height: calc(100% - 108px);
}
.widget-searchschool__advancedfilters__header {
  background-color: var(--color-green);
  color: #fff;
  height: 60px;
}
.widget-searchschool__advancedfilters__header h2 {
  color: inherit;
  margin-bottom: 0;
}
.widget-searchschool__advancedfilters__header .centercontent {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-searchschool__advancedfilters__content {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  max-height: calc(100% - 120px);
  overflow-y: auto;
}
.widget-searchschool__advancedfilters__filters {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.widget-searchschool__advancedfilters__filters h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-searchschool__advancedfilters__filters .wh-form__optionlabel {
  font-size: 15px;
  max-width: 100%;
}
.widget-searchschool__advancedfilters__filters hr {
  margin: 15px 0;
}
.widget-searchschool__advancedfilters__filters .wh-form__fieldline {
  margin-bottom: 10px;
}
.widget-searchschool__advancedfilters__filters__group {
  flex: 1 1 25%;
  max-width: 25%;
}
.widget-searchschool__advancedfilters__filters--threecols .widget-searchschool__advancedfilters__filters__group {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
}
.widget-searchschool__advancedfilters__footer {
  height: 60px;
  padding: 0 24px;
}
.widget-searchschool__advancedfilters__submit {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: 500 20px/26px "TypoPRO Dosis", "Dosis", Arial;
  display: flex;
  padding: 12px 15px 12px 30px;
  align-items: center;
  margin: 0 auto;
  background-color: var(--color-magenta);
  color: #fff;
  width: 100%;
  height: 52px;
  border-radius: 26px;
  max-width: 566px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.24);
  justify-content: space-between;
}
.widget-searchschool__advancedfilters__submit i {
  font-size: 18px;
  width: 28px;
  height: 28px;
  color: var(--color-magenta);
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  line-height: 28px;
}
.widget-searchschool__results button {
  text-decoration: none;
  color: var(--color-green);
  font-family: inherit;
  font-size: 11px;
  font-weight: normal;
  display: block;
  border-radius: 2px;
  height: 22px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 20px;
  border: 1px solid #ddd;
  padding: 0 7px 0 5px;
  position: relative;
  background-color: #fff;
  text-align: left;
  transition: background-color 0.3s;
  box-shadow: none;
  -webkit-appearance: none;
  margin: 0;
}
.widget-searchschool__results button i {
  display: inline-block;
  padding-right: 5px;
}
.widget-searchschool__results button:hover, .widget-searchschool__results button:focus {
  background-color: #eee;
}
.widget-searchschool__results tr.disabled {
  background-color: #fcfcfc;
  color: #ccc;
}
.widget-searchschool__results .showall td {
  text-align: center;
  padding-top: 20px;
}
.widget-searchschool__register {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-searchschool__register p {
  margin-bottom: 0;
}
@media (max-width: 600px) {
  .widget-searchschool__register {
    display: block;
  }
  .widget-searchschool__register p {
    margin-bottom: 15px;
  }
}