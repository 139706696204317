.widget-image {
  font-size: 14px;
  line-height: 135%;
}
.widget-image__image {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}
.widget-image__caption {
  margin-top: 10px;
}