.dashboard-totalopen {
  margin-bottom: 24px;
}

.dashboard-loginname {
  font-size: 17px;
  text-align: right;
  margin-bottom: 24px;
}

.lecturestable--multiple th[hidden],
.lecturestable--multiple td[hidden] {
  display: table-cell;
}

.statusicon {
  color: var(--color-purple);
  font-size: 17px;
}
.statusicon--matched {
  color: var(--color-green);
}

.primaryteacher_details a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.primaryteacher_details__label {
  font-size: 90%;
  font-weight: 700;
}
.primaryteacher_details p {
  margin-bottom: 0;
}

#page-thankyou .outlinepanel {
  display: flex;
  flex-flow: column;
}
#page-thankyou .thankyoupage__button {
  margin-top: 24px;
  align-self: flex-end;
}

.dasboard-ownlectures .export {
  text-decoration: none;
}
.dasboard-ownlectures .export span {
  text-decoration: underline;
}

.partner-matchtypebuttons a {
  background-color: var(--color-green);
  display: block;
  height: 106px;
  width: 100%;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 24px;
  font: 700 25px/130% "TypoPRO Dosis Buttons", "Dosis", Arial;
  position: relative;
  z-index: 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  font-size: clamp(22px, 2.1dvw, 25px);
}
.partner-matchtypebuttons a + a {
  border-radius: 0 0 8px 8px;
}
.partner-matchtypebuttons a + a::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
}
.partner-matchtypebuttons a:hover {
  text-shadow: 0 0 1px #fff;
}
.partner-matchtypebuttons a span {
  flex: 1 1 auto;
  display: block;
  margin-right: 10px;
}
.partner-matchtypebuttons a i {
  flex: none;
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  color: var(--color-green);
}

.gastlessen-dialog--notavailable h2 {
  font-size: 20px;
}

#matchmailschool .panel__header > * {
  vertical-align: baseline;
}
#matchmailschool .panel__header i {
  padding-right: 10px;
  font-size: 20px;
}
#matchmailschool hr {
  margin-bottom: 15px;
}
#matchmailschool .block--edit {
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
}
#matchmailschool #editmailcontent::after {
  font-weight: 400;
  font-size: 20px;
  content: "\f4ff";
  order: -1;
  padding-left: 0;
  padding-right: 15px;
}

#messageform .wh-form__button {
  min-width: 0;
}
#messageform textarea {
  width: 100%;
  margin-bottom: 10px;
}

.mysettingspage .editemail, .mysettingspage .editpassword {
  cursor: pointer;
  color: var(--color-green);
  display: inline-block;
}
.mysettingspage .editemail {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  color: var(--color-green);
  border: 1px solid var(--color-green);
  margin-left: 10px;
  font-size: 16px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
}
.mysettingspage .editpassword {
  margin-top: 10px;
}
.mysettingspage .editpassword:hover {
  text-decoration: underline;
}

.block--twocols {
  display: flex;
  margin-left: -24px;
  justify-content: space-between;
}
.block--twocols .block__col {
  flex: 1 1 50%;
  max-width: 50%;
  padding-left: 24px;
}
@media (max-width: 600px) {
  .block--twocols {
    display: block;
  }
  .block--twocols .block__col {
    max-width: 100%;
  }
}

.partnerrequestpage h2, .partnermatchdetails h2 {
  padding-bottom: 5px;
}
.partnerrequestpage .searchteacher__addteacher, .partnermatchdetails .searchteacher__addteacher {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.partnerrequestpage .searchteacher__addteacher > *:first-child, .partnermatchdetails .searchteacher__addteacher > *:first-child {
  margin-right: 10px;
}
.partnerrequestpage .wh-form__fieldgroup--teacheroptions .wh-form__fields, .partnermatchdetails .wh-form__fieldgroup--teacheroptions .wh-form__fields {
  margin-top: 10px;
}
.partnerrequestpage .wh-form__fieldgroup--teacheroptions .wh-form__label, .partnermatchdetails .wh-form__fieldgroup--teacheroptions .wh-form__label {
  font-family: var(--font-family-dosis);
  padding-bottom: 5px;
  color: var(--color-purple);
  font-weight: 700;
  line-height: 130%;
  font-size: 17px;
}
.partnerrequestpage .searchteacher__addteacher, .partnerrequestpage .searchteacher__addself, .partnermatchdetails .searchteacher__addteacher, .partnermatchdetails .searchteacher__addself {
  line-height: 155%;
  font-size: 95%;
  margin-bottom: 15px;
}
.partnerrequestpage .searchteacher__addteacher[hidden], .partnerrequestpage .searchteacher__addself[hidden], .partnermatchdetails .searchteacher__addteacher[hidden], .partnermatchdetails .searchteacher__addself[hidden] {
  display: none;
}
.partnerrequestpage .addteacherbutton, .partnerrequestpage .addselfbutton, .partnermatchdetails .addteacherbutton, .partnermatchdetails .addselfbutton {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  text-decoration: underline;
  text-underline-position: under;
  color: var(--color-green);
}
.partnerrequestpage .match-selectlectures, .partnermatchdetails .match-selectlectures {
  position: relative;
  display: flex;
  overflow: hidden;
}
.partnerrequestpage .match-selectlectures input[type=checkbox] + label, .partnermatchdetails .match-selectlectures input[type=checkbox] + label {
  top: 0;
}
.partnerrequestpage .sendschoolmailform, .partnermatchdetails .sendschoolmailform {
  padding-left: 15px;
}
.partnerrequestpage.noschoolmail .panel--matchmailschool, .partnermatchdetails.noschoolmail .panel--matchmailschool {
  display: none;
}
.partnerrequestpage .panel--matchmailschool .panel__header, .partnermatchdetails .panel--matchmailschool .panel__header {
  display: flex;
  align-items: flex-start;
  padding-bottom: 15px;
}
.partnerrequestpage .panel--matchmailschool .panel__header__icon, .partnermatchdetails .panel--matchmailschool .panel__header__icon {
  position: relative;
  top: 5px;
}
.partnerrequestpage .panel--matchmailschool .panel__footer, .partnermatchdetails .panel--matchmailschool .panel__footer {
  background-color: transparent;
  justify-content: center;
  margin-bottom: 0;
}
.partnerrequestpage .panel--matchmailschool .panel__footer .button, .partnermatchdetails .panel--matchmailschool .panel__footer .button {
  color: var(--color-green-dark);
  border-color: var(--color-green-dark);
}
.partnerrequestpage .panel--matchmailschool, .partnermatchdetails .panel--matchmailschool {
  background-color: var(--color-grey-light);
  padding: 15px;
}
.partnerrequestpage .panel--matchmailschool .panel__content, .partnermatchdetails .panel--matchmailschool .panel__content {
  border-radius: 5px;
}
.partnerrequestpage.noschoolmail .panel--requestsummary, .partnermatchdetails.noschoolmail .panel--requestsummary {
  display: block;
}
.partnerrequestpage .block + .block, .partnermatchdetails .block + .block {
  margin-top: 30px;
}
.partnerrequestpage .infopanel.form-school a, .partnermatchdetails .infopanel.form-school a {
  margin-top: 15px;
  display: inline-block;
}
.partnerrequestpage .form-groupopenrequests a, .partnermatchdetails .form-groupopenrequests a {
  text-align: right;
  display: block;
  font-size: 20px;
}
.partnerrequestpage .storetype-buttongroup, .partnermatchdetails .storetype-buttongroup {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  margin-top: 30px;
}
.partnerrequestpage .storetype-buttongroup > div, .partnermatchdetails .storetype-buttongroup > div {
  flex: 1 1 50%;
  max-width: calc(50% - 15px);
}
.partnerrequestpage .storetype-buttongroup button, .partnermatchdetails .storetype-buttongroup button {
  width: 100%;
}
.partnerrequestpage .storetype-buttongroup p, .partnermatchdetails .storetype-buttongroup p {
  margin-top: 20px;
  font-size: 14px;
  line-height: 160%;
}
@media (max-width: 500px) {
  .partnerrequestpage .storetype-buttongroup, .partnermatchdetails .storetype-buttongroup {
    display: block;
  }
  .partnerrequestpage .storetype-buttongroup > div, .partnermatchdetails .storetype-buttongroup > div {
    max-width: none;
  }
  .partnerrequestpage .storetype-buttongroup > div + div, .partnermatchdetails .storetype-buttongroup > div + div {
    padding-top: 20px;
    border-top: 1px solid var(--color-grey-lines);
  }
}
.partnerrequestpage .button--reservation::after, .partnermatchdetails .button--reservation::after {
  content: "\f073";
}
.partnerrequestpage .button--setlecturer::after, .partnermatchdetails .button--setlecturer::after {
  content: "\f00c";
}
.partnerrequestpage .lecturerform[hidden], .partnermatchdetails .lecturerform[hidden] {
  display: none;
}
.partnerrequestpage .lecturerform__note p, .partnermatchdetails .lecturerform__note p {
  font-size: 13px;
  margin-bottom: 30px;
}
.partnerrequestpage--reserved .partnerrequestpage__reservedpanel, .partnerrequestpage--reserved .partnermatchdetails__reservedpanel, .partnermatchdetails--reserved .partnerrequestpage__reservedpanel, .partnermatchdetails--reserved .partnermatchdetails__reservedpanel {
  display: block;
}
.lecturerform:not([hidden]) ~ .partnerrequestpage__reservedpanel, .lecturerform:not([hidden]) ~ .partnermatchdetails__reservedpanel {
  display: none;
}
.partnerrequestpage--matched .lecturerform, .partnermatchdetails--matched .lecturerform {
  display: block;
}
.partnerrequestpage--matched .storetype-buttongroup, .partnerrequestpage.noschoolmail .thankyoutext .sendschoolmail, .partnermatchdetails--matched .storetype-buttongroup, .partnermatchdetails.noschoolmail .thankyoutext .sendschoolmail {
  display: none;
}
.partnerrequestpage #page-check .messageform__note, .partnermatchdetails #page-check .messageform__note {
  font-size: 13px;
}
.partnerrequestpage #page-check .checkpage__buttongroup, .partnermatchdetails #page-check .checkpage__buttongroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin-top: 30px;
}
.partnerrequestpage #page-check .checkpage__buttongroup button, .partnermatchdetails #page-check .checkpage__buttongroup button {
  margin-top: 10px;
}
@media (max-width: 500px) {
  .partnerrequestpage #page-check .checkpage__buttongroup, .partnermatchdetails #page-check .checkpage__buttongroup {
    justify-content: flex-end;
  }
  .partnerrequestpage #page-check .checkpage__buttongroup button, .partnermatchdetails #page-check .checkpage__buttongroup button {
    width: 100%;
  }
}
.partnerrequestpage .storetype-matched, .partnerrequestpage .storetype-reserved, .partnermatchdetails .storetype-matched, .partnermatchdetails .storetype-reserved {
  display: none;
}
.partnerrequestpage--matched .storetype-matched, .partnerrequestpage--reserved .storetype-reserved, .partnermatchdetails--matched .storetype-matched, .partnermatchdetails--reserved .storetype-reserved {
  display: block;
}