#dialog-sendactivationlink {
  max-width: 400px;
}

.classportalpage__requestlecturelink {
  margin-top: 15px;
}

.classportalpage-schooltypebuttons {
  margin-bottom: 24px;
}
.classportalpage-schooltypebuttons a {
  background-color: var(--color-green);
  display: block;
  min-height: 100px;
  width: 100%;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  font-size: 25px;
  font-weight: 500;
  line-height: 130%;
  position: relative;
  z-index: 0;
  border-radius: 0;
  overflow: hidden;
  font-size: clamp(22px, 2dvw, 25px);
  transition: background-color 0.3s;
}
.classportalpage-schooltypebuttons a:first-child {
  border-radius: 8px 8px 0 0;
}
.classportalpage-schooltypebuttons a:last-child {
  border-radius: 0 0 8px 8px;
}
.classportalpage-schooltypebuttons a + a::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 24px;
  right: 24px;
  top: 0;
  height: 1px;
  background-color: #fff;
}
.classportalpage-schooltypebuttons a:hover {
  text-shadow: 0 0 1px #fff;
  background-color: var(--color-green-dark);
}
.classportalpage-schooltypebuttons a span {
  flex: 1 1 auto;
  display: block;
  margin-right: 10px;
}
.classportalpage-schooltypebuttons a i {
  flex: none;
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  color: var(--color-green);
}
.classportalpage-schooltypebuttons p {
  margin: 15px 24px;
}

.classportal_registerform__footnote p {
  font-size: 14px;
}
.classportal_registerform__footnote > *:last-child {
  margin-bottom: 0;
}

.classportal_registerform__fields {
  display: flex;
  margin-left: -24px;
  flex-wrap: wrap;
}
.classportal_registerform__fields .wh-form__fieldgroup {
  flex: 1 1 50%;
  max-width: calc(50% - 24px);
  margin-left: 24px;
}
@media (max-width: 550px) {
  .classportal_registerform__fields {
    margin-left: 0;
    display: block;
  }
  .classportal_registerform__fields .wh-form__fieldgroup {
    max-width: 100%;
    margin-left: 0;
  }
}

.classportalpage p {
  line-height: 160%;
}
.classportalpage .block__buttongroup::after {
  content: "";
  clear: both;
  display: block;
}
.classportalpage #page-check .block {
  margin-bottom: 30px;
}
.classportalpage #page-check .form-groups table {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
.classportalpage #page-check .form-groups table td {
  padding-bottom: 7px;
}

#classportal_groupsform .groups-note {
  font-style: italic;
  color: var(--color-green);
}