@charset "UTF-8";
html {
  background-color: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
}

body {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  width: 100vw;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
html.mobilelayout.showmobilemenu body, html.hidescroll body {
  overflow: hidden;
}

.fa-twitter::before {
  content: "𝕏" !important;
  font-size: 110%;
  font-weight: 700;
  font-family: Arial-black;
}

h1 {
  font-size: clamp(30px, 2.5dvw, 36px);
}

h2 {
  font-size: clamp(18px, 2.6dvw, 20px);
}

h3 {
  font-size: clamp(16px, 2.2dvw, 17px);
}

html.dashboardpage h2 {
  font-size: clamp(20px, 3.1dvw, 24px);
}

p.note {
  color: var(--color-grey-placeholder);
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
}
p.note .info-icon {
  font-size: 17px;
}

header, footer {
  flex: none;
}

main {
  flex: 1 0 auto; /* vertical (column) outfill */
  max-width: 100%;
  padding: 0;
  position: relative;
  z-index: 0;
}
html.dashboardpage main, html.classportal_landingpage main, html.classportal_registrationpage main {
  margin-top: 142px;
}
html.classportal_registrationpage main {
  margin-top: 0;
}

.help-panel {
  display: block;
  width: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 100;
  transition: width 300ms;
}
.help-panel__content {
  width: 454px;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column;
}
.help-panel__close {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 18px;
  top: 20px;
  z-index: 1;
  color: var(--color-green);
}
.help-panel__close i {
  vertical-align: top;
}
.help-panel__title {
  flex: none;
  min-height: 108px;
  display: flex;
  align-items: flex-end;
  padding: 24px 60px 18px 24px;
  border-bottom: 1px solid var(--color-grey-lines);
}
.help-panel__title h2 {
  margin-bottom: 0;
}
.help-panel__description {
  flex: 1 1 auto;
  position: relative;
}
.help-panel__scrollarea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 100%;
}
html.showhelppanel .help-panel {
  width: 454px;
  max-width: 100%;
}

html.showhelppanel body {
  overflow: hidden;
}
html.showhelppanel body::after {
  content: "";
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

article {
  margin-top: 20px;
}
article > * {
  max-width: calc(66.6666% - 10px);
}
article > *.wide {
  max-width: 100%;
}
article p + .contentcols {
  margin-top: 30px;
}

html.searchpage main {
  padding-top: 35px;
}

.introblock {
  margin-bottom: 32px;
}

.centercontent, article {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1203px;
  padding-left: 24px;
  padding-right: 24px;
}
@media (max-width: 600px) {
  .centercontent, article {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.wh-rtd__anchor {
  display: inline-block;
  position: absolute;
  transform: translateY(-102px);
}
html.mobilelayout .wh-rtd__anchor {
  transform: translateY(-42px);
}

/* columns (default 2/1) */
.contentcols {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
html.dashboardpage .introblock .contentcols__col + .contentcols__col, html.classportal_landingpage .introblock .contentcols__col + .contentcols__col {
  align-self: flex-start;
}
.contentcols--dropshadow .contentcols__col:not(.contentcols__col--no-dropshadow) {
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.27);
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
}
.contentcols__col {
  flex: 1 1 66.666%;
  max-width: calc(66.666% - 8px);
}
.contentcols__col--nostretch {
  align-self: flex-start;
}
.contentcols__col--shiftdown {
  margin-top: 150px;
}
.contentcols__col + .contentcols__col {
  flex-basis: 33.3333%;
  max-width: calc(33.3333% - 16px);
  margin-right: 0;
}
.contentcols--equal .contentcols__col {
  flex-basis: 50%;
  max-width: calc(50% - 12px);
}
.contentcols--equal .contentcols__col + .contentcols__col {
  flex-basis: 50%;
  max-width: calc(50% - 12px);
}

html.classportal_registrationpage:not([data-active-tab="0"]) .header-images {
  display: none;
}
html.classportal_registrationpage:not([data-active-tab="0"]) .contentcols__col--shiftdown {
  margin-top: 0;
}

@media (max-width: 850px) {
  article > * {
    max-width: 100%;
  }
  .contentcols {
    display: block;
  }
  .contentcols__col {
    max-width: 100% !important;
  }
  .contentcols__col + .contentcols__col {
    margin-top: 24px;
  }
}
/* wizardtabs */
.wizardtabs {
  padding-top: 30px;
  max-width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  overflow-x: auto;
}
.wizardtabs ol {
  min-width: 1024px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  counter-reset: li;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
  height: 52px;
}
html.wizardpage .wizardtabs ol {
  min-width: 768px;
}
.wizardtabs ol > li {
  display: block;
  flex: 1 1 100%;
  background-color: #FFF;
  height: 100%;
  font: 700 19px/28px "TypoPRO Dosis Buttons", "Dosis", Arial;
  position: relative;
  padding: 10px 10px 10px 50px;
  color: var(--color-green-dark);
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wizardtabs ol > li > .angle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 100%;
  background-color: transparent;
}
.wizardtabs ol > li > .angle::before, .wizardtabs ol > li > .angle::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  width: 22px;
  height: 50px;
  transform-origin: 0 0;
  border: 1px solid #BDBDBD;
}
.wizardtabs ol > li > .angle::before {
  transform-origin: top left;
  transform: rotate(-35deg);
  top: 0;
  left: 0;
}
.wizardtabs ol > li > .angle::after {
  transform-origin: bottom left;
  transform: rotate(35deg);
  bottom: 0px;
  right: -4px;
}
.wizardtabs ol > li.beforeactive .angle::before, .wizardtabs ol > li.beforeactive .angle::after {
  background-color: var(--color-magenta);
}
.wizardtabs ol > li:first-child {
  border-radius: 24px 0 0 24px;
}
.wizardtabs ol > li:last-child {
  border-radius: 0 24px 24px 0;
}
.wizardtabs ol > li::before {
  font: 600 18px/18px "TypoPRO Dosis", "Dosis", Arial;
  padding-top: 3px;
  content: counter(li);
  counter-increment: li;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -12px;
  background-color: var(--color-green-dark);
  color: #fff;
  border-radius: 50%;
  text-align: center;
}
.wizardtabs ol > li.active {
  background-color: var(--color-magenta);
  color: #fff;
}
.wizardtabs ol > li.active::before {
  color: var(--color-magenta);
  background-color: #fff;
}
.wizardtabs ol > li.active:last-child {
  background-color: var(--color-green);
}
.wizardtabs ol > li.active:last-child::before {
  color: var(--color-green-dark);
}
.wizardtabs ol > li.active ~ li {
  background-color: #fff;
  color: var(--color-magenta);
}
.wizardtabs ol > li.active ~ li::before {
  color: #fff;
  background-color: var(--color-magenta);
}
html.submitted .wizardtabs ol > li {
  cursor: default;
  background-color: #f3f3f3;
}
html.submitted .wizardtabs ol > li .angle::before,
html.submitted .wizardtabs ol > li .angle::after {
  background-color: inherit;
}
html.submitted .wizardtabs ol > li.beforeactive .angle::before, html.submitted .wizardtabs ol > li.beforeactive .angle::after {
  background-color: var(--color-green);
}

.wh-page {
  display: none;
}

.wh-page--visible {
  display: block;
}

html.wizardpage main::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  height: 200px;
  background-color: #f9f9f9;
  z-index: -1;
}

/* basic panel */
.contentcols__col + .contentcols__col .panel p {
  font-size: 14px;
}

.panel {
  border: 1px solid #D2D2D2;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.panel__helpbutton {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 18px;
  top: 18px;
  z-index: 1;
  color: var(--color-green);
}
.panel__helpbutton > i {
  vertical-align: top;
}
.panel__helpbutton + .panel__header {
  margin-right: 45px;
}
.panel a, .panel p > a {
  color: var(--color-green-dark);
  text-underline-position: under;
  text-decoration: underline;
}
.panel__header {
  max-width: 100%;
  padding: 15px 20px 0;
  position: relative;
}
.panel__header h1, .panel__header h2 {
  margin-bottom: 0;
  padding-bottom: 0;
}
.panel__header h1 + p, .panel__header h2 + p {
  margin-top: 5px;
}
.panel__header > *:last-child {
  margin-bottom: 0;
}
.panel__content {
  padding: 20px;
  border-radius: 0 0 7px 7px;
  background-color: #fff;
}
.panel__content > *:last-child {
  margin-bottom: 0;
}
.panel__content h4 {
  margin-bottom: 10px;
}
.panel__content__button {
  display: inline-block;
  background-color: var(--color-green);
  white-space: nowrap;
  color: #fff;
  border-radius: 2px;
  line-height: 20px;
  font-size: 14px;
  padding: 8px 15px 8px 15px;
  text-decoration: none;
  transition: opacity 0.2s;
}
.panel__content__button i {
  display: inline-block;
  padding-right: 10px;
}
.panel__content__button:hover {
  opacity: 0.85;
}
.panel__footer {
  margin: 15px 0;
  padding: 8px 6px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font-size: 14px;
  background-color: #f6f6f6;
}

.panel table,
table.lecturelist {
  font-size: 14px;
  width: 100%;
}
.panel table th,
table.lecturelist th {
  font-weight: 600;
  padding: 8px 6px;
  font-family: "TypoPRO Dosis", "Dosis", Arial;
  background-color: #f6f6f6;
  border-bottom: 1px solid var(--color-purple);
}
.panel table th:first-child,
table.lecturelist th:first-child {
  padding-left: 14px;
}
.panel table th:first-child:empty,
table.lecturelist th:first-child:empty {
  width: 0;
}
.panel table.form-groupstable td,
table.lecturelist.form-groupstable td {
  color: #000;
}
.panel table .lecturelist__city,
table.lecturelist .lecturelist__city {
  font-size: 85%;
}
.panel table td,
table.lecturelist td {
  border-top: 1px solid #ddd;
  padding: 8px 6px;
  line-height: 120%;
}
.panel table td:first-child,
table.lecturelist td:first-child {
  padding-left: 14px;
}
.panel table td:first-child:empty,
table.lecturelist td:first-child:empty {
  width: 0;
}
.panel table .form-groupfields__maincol,
table.lecturelist .form-groupfields__maincol {
  font-weight: 700;
}
.panel table tr:has(+ .schoolrequestpage__row__extra) > td,
.panel table tr:has(+ .partnerrequestpage__row__extra) > td,
table.lecturelist tr:has(+ .schoolrequestpage__row__extra) > td,
table.lecturelist tr:has(+ .partnerrequestpage__row__extra) > td {
  padding-bottom: 0;
}
.panel table .schoolrequestpage__row__extra td,
.panel table .partnerrequestpage__row__extra td,
table.lecturelist .schoolrequestpage__row__extra td,
table.lecturelist .partnerrequestpage__row__extra td {
  font-size: 95%;
  opacity: 0.9;
  padding-top: 0;
  border-top: 0 none;
}

.lecturelist tbody > tr:not(.disabled) {
  cursor: pointer;
}
.lecturelist--hideschooltype .lecturelist__col__schooltype {
  display: none;
}
.lecturelist__row__icon {
  text-align: center;
  width: 40px;
}
.lecturelist__row__title {
  position: relative;
}
.lecturelist__row__title--icon {
  padding-left: 25px !important;
}
.lecturelist__row__title--icon > i {
  position: absolute;
  left: 6px;
  top: 12px;
}
.lecturelist__row__narrow {
  width: 45px;
}
.lecturelist__togglegroupedrows__hide {
  display: none;
}
.lecturelist__togglegroupedrows--visible .lecturelist__togglegroupedrows__show {
  display: none;
}
.lecturelist__togglegroupedrows--visible .lecturelist__togglegroupedrows__hide {
  display: inline-block;
}

/* infopanel with lightgreen background */
.infopanel {
  padding: 16px 15px;
  background-color: var(--color-green-light);
  border-radius: 7px;
  position: relative;
  margin-bottom: 30px;
}
.infopanel--tip {
  margin-bottom: 10px;
}
.infopanel[hidden] {
  display: none;
}
.infopanel:last-child {
  margin-bottom: 0;
}
.infopanel p {
  font-size: 14px;
}
.infopanel h3 {
  color: var(--color-green-dark);
}
.infopanel__icon {
  font-size: 22px;
  color: var(--color-green);
  position: absolute;
  right: 15px;
  top: 15px;
}
.infopanel--tip .infopanel__title {
  padding-right: 35px;
}
.infopanel--tip + .infopanel--tip .infopanel__title {
  padding-right: 0;
}
.infopanel--tip + .infopanel--tip .infopanel__icon {
  display: none;
}
.infopanel__buttonbar a {
  width: 100%;
}
.infopanel--purple {
  background-color: var(--color-purple);
}
.infopanel--icon {
  padding-left: 55px;
}
.infopanel--spaceright {
  padding-right: calc(33.3333% + 10px);
}
.infopanel .icon {
  color: var(--color-green);
  position: absolute;
  top: 20px;
  left: 17px;
  font-size: 15px;
}
.infopanel > *:last-child {
  margin-bottom: 0;
}

.sidenav a {
  text-decoration: none;
  color: inherit;
  text-underline-position: under;
}
.sidenav__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sidenav__item__link {
  display: flex;
  align-items: baseline;
}
.sidenav__item__link:hover > span {
  text-decoration: underline;
}
.sidenav__item__link::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f054";
  color: var(--color-green);
  font-weight: 700;
  font-size: 14px;
  margin-right: 10px;
}
.sidenav__item__link--selected {
  font-weight: 700;
}
.sidenav__item + .sidenav__item {
  margin-top: 7px;
}

/* outlinepanel */
.outlinepanel {
  border: 1px solid var(--color-green);
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 30px;
  background-color: #fff;
}
.outlinepanel--spaceright {
  padding-right: calc(33.3333% + 10px);
}
.outlinepanel > *:last-child {
  margin-bottom: 0;
}