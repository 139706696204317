.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 48px;
  padding-top: 6px;
  font-size: 16px;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: var(--color-grey-fieldborder);
  font-weight: 900;
  border-radius: 50%;
  background-color: #fff;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex-grow: 100;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: 15px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(21, 162, 55, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(21, 162, 55, 0.8) 50%, rgba(21, 162, 55, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}