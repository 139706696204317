.wh-autocomplete-values {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 10px !important;
  position: absolute;
  top: 100%;
  margin-top: -20px;
  left: 16px;
  right: 16px;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 2px 12px 7px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.wh-autocomplete-values > li {
  padding: 3px 15px;
  line-height: 28px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.15s;
  outline: none;
  color: #3e3e36;
  margin-bottom: 0 !important;
}
.wh-autocomplete-values > li::before {
  display: none !important;
}
.wh-autocomplete-values > li.noresults {
  font-weight: normal;
  font-style: italic;
  color: #999;
  cursor: default;
}
.wh-autocomplete-values > li.suggestion:focus, .wh-autocomplete-values > li.suggestion:hover {
  background-color: #f1f1f1;
}
.wh-autocomplete-values > li .city {
  display: inline-block;
  padding-left: 6px;
  font-size: 11px;
  opacity: 0.6;
}
.wh-autocomplete-values > li .city::before {
  content: "(";
}
.wh-autocomplete-values > li .city::after {
  content: ")";
}
.wh-autocomplete-values > li .match {
  font-weight: bold;
}