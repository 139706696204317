.widget-lecturetypes {
  font-size: 14px;
  line-height: 135%;
}
.widget-lecturetypes ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding: 20px;
}
.widget-lecturetypes__item + .widget-lecturetypes__item {
  margin-top: 10px;
}
.widget-lecturetypes__item {
  color: var(--color-green);
}
.widget-lecturetypes__item__link {
  text-decoration: none !important;
  color: inherit;
  display: block;
  position: relative;
  padding-left: 35px;
}
.widget-lecturetypes__item__link .widget-lecturetypes__item__title {
  text-decoration: underline;
}
.widget-lecturetypes__item__title {
  color: inherit;
  margin-bottom: 5px;
}
.widget-lecturetypes__item__icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 23px;
}
.widget-lecturetypes__item__groupnums {
  color: #666;
  margin-bottom: 5px;
}
.widget-lecturetypes__item__description {
  font-size: 15px !important;
  color: var(--color-black);
}