:root {
  --color-black: #333333;
  --color-green: #13A538;
  --color-green-light: #E7F5EB;
  --color-green-dark: #1E7B26;
  --color-green-darker: #088638;
  --color-purple: #641C76;
  --color-purple-light: #F2E5F1;
  --color-magenta: #C20A5B;
  --color-red: #EE0000;
  --color-grey-light: #EEEEEE;
  --color-grey-lines: #DADADA;
  --color-grey-placeholder: #757575;
  --color-grey-fieldborder: #929292;
  --font-family-dosis: "TypoPRO Dosis", "Dosis", Arial;
}

.fg-green {
  color: var(--color-green);
}

.fg-red {
  color: var(--color-red);
}

.fg-purple {
  color: var(--color-purple);
}

.fg-grey {
  color: #929292;
}

.bg-green {
  background-color: var(--color-green);
}

.bg-red {
  background-color: var(--color-red);
}

.bg-purple {
  background-color: var(--color-purple);
}

.font-dosis {
  font-family: var(--font-family-dosis);
}

.bg-lighgreen {
  color: var(--color-green-light);
}

dl {
  line-height: 140%;
}
dl::after {
  content: "";
  display: block;
  clear: both;
}
dl > * {
  float: left;
  padding-bottom: 5px;
}
dl dt {
  clear: left;
  width: 170px;
  font-weight: bold;
}
dl dd {
  max-width: calc(100% - 170px);
}