.wh-wrdauth-form .wh-form__prefillarea {
  display: none;
}
.wh-wrdauth-form .wh-wrdauth-form__intro {
  margin-bottom: 15px;
}
.wh-wrdauth-form .wh-wrdauth-login__forgotpasswordlinkholder {
  margin-top: 15px;
  font-size: 14px;
}
.wh-wrdauth-form .wh-wrdauth-login__forgotpasswordlinkholder a {
  text-decoration: underline;
}
.wh-wrdauth-form .wh-form__buttongroup {
  padding-bottom: 0;
}