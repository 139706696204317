/* radio / checkbox styling */
.wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}

.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  top: 6px;
  width: 24px;
  max-width: 24px;
  flex: 0 0 24px;
  height: 24px;
  border: 1px solid var(--color-grey-fieldborder);
  background-color: #fff;
  border-radius: 5px;
}
.wh-styledinput input[type=radio] + label::after,
.wh-styledinput input[type=checkbox] + label::after {
  font-family: "Font Awesome 5 Pro";
  content: "";
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-grey-fieldborder);
  font-size: 16px;
  display: block;
  padding: 3px 0 0 3px;
  width: 100%;
  height: 100%;
  line-height: 16px;
  transition: all 0.2s;
}
.wh-styledinput input[type=radio]:focus + label,
.wh-styledinput input[type=checkbox]:focus + label {
  border-color: var(--color-green);
  box-shadow: 0 0 0 3px rgba(21, 162, 55, 0.15);
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #C5C5C5;
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label + *,
.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: var(--color-grey-fieldborder);
}

.wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 5px;
}

.wh-form__optiondata > * {
  padding-left: 15px;
}

.wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}

.wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 5px;
}

.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-styledinput input[type=radio] + label::after {
  border: 3px solid #fff;
  border-radius: 50%;
}
.wh-styledinput input[type=radio]:checked + label::after {
  background-color: var(--color-green);
}
.wh-styledinput input[type=radio]:checked + label {
  border-color: var(--color-green);
  border-width: 2px;
}
.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: #C5C5C5;
}

.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\f00c";
  background-color: var(--color-green);
  color: #fff;
}
.wh-styledinput input[type=checkbox]:checked + label {
  border-color: var(--color-green);
}
.wh-styledinput input[type=checkbox][disabled] + label::after {
  content: "";
}
.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  content: "\f00c";
  background-color: #F2F2F2;
  color: #C5C5C5;
}

.wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: var(--color-red);
}

.wh-styledinput--nativecheckbox input[type=checkbox] {
  display: block;
  position: relative;
  top: 6px;
  flex: none;
  left: 0;
  width: 24px;
  height: 24px;
  min-height: 24px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid var(--color-grey-fieldborder);
  border-radius: 5px;
}
.wh-styledinput--nativecheckbox input[type=checkbox]::after {
  position: absolute;
  top: 2px;
  left: 3px;
  display: block;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  font-size: 16px;
}
.wh-styledinput--nativecheckbox input[type=checkbox]:checked {
  background-color: var(--color-green);
}
.wh-styledinput--nativecheckbox input[type=checkbox]:checked::after {
  color: #fff;
  content: "\f00c";
}
.wh-styledinput--nativecheckbox input[type=checkbox][disabled]:checked::after {
  color: #aaa;
}
.wh-styledinput--nativecheckbox input[type=checkbox][disabled], .wh-styledinput--nativecheckbox input[type=checkbox][disabled]:checked {
  background-color: #f2f2f2;
}

.wh-form__fieldgroup--checkboxslider .wh-form__optionlabel {
  font: bold 18px/100% "TypoPRO Dosis", "Dosis", Arial;
  padding-left: 10px;
  color: #767676;
}
.wh-form__fieldgroup--checkboxslider input[type=checkbox] {
  width: 45px;
  max-width: 45px;
  flex: none;
  top: 4px;
  border-radius: 12px;
  background-color: #919191;
  color: #000;
}
.wh-form__fieldgroup--checkboxslider input[type=checkbox]::after {
  position: absolute;
  top: 2px;
  left: 2px;
  content: "";
  transition: left 200ms;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
}
.wh-form__fieldgroup--checkboxslider input[type=checkbox]:checked {
  background-color: var(--color-green);
}
.wh-form__fieldgroup--checkboxslider input[type=checkbox]:checked::after {
  left: 23px;
}
.wh-form__fieldgroup--checkboxslider input[type=checkbox]:checked ~ .wh-form__optiondata .wh-form__optionlabel {
  color: #56145F;
}