.wh-form__arrayinput {
  width: 1px;
  padding: 0;
  min-height: 0;
}

.wh-form__fieldgroup--array .wh-form__arrayrow {
  position: relative;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 10px;
  padding-right: 65px;
}

.wh-form__arraydelete {
  align-self: flex-start;
  position: absolute;
  right: 0;
  top: 11px;
  font-size: 20px;
  color: var(--color-purple);
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  line-height: 45px;
  text-align: center;
  width: 48px;
  height: 48px;
  background-color: #fff;
  transition: background-color 200ms;
  border: 2px solid #13A538;
}
.wh-form__arraydelete::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f2ed"; /* trash-alt */
  position: relative;
  top: -2px;
}
.wh-form__arraydelete:hover {
  background-color: #f1f1f1;
}

.wh-form__arrayadd {
  font: 600 20px/30px var(--font-family-dosis);
  background-color: #fff;
  border: 1px solid var(--color-green);
  cursor: pointer;
  color: var(--color-green-dark);
  border-radius: 5px;
  padding: 7px 15px 9px 43px;
  position: relative;
  display: inline-block;
}
.wh-form__fieldgroup--array.wh-form__array--maxrows .wh-form__arrayadd {
  display: none;
}
.wh-form__arrayadd::before {
  content: "Toevoegen";
}
.wh-form__arrayadd::after {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f067"; /* plus */
}

.wh-form__fieldgroup--groups .wh-form__fieldgroup {
  display: block;
}
.wh-form__fieldgroup--groups .wh-form__fieldgroup[hidden] {
  display: none;
}
.wh-form__fieldgroup--groups .wh-form__fieldgroup > .wh-form__fields {
  max-width: 100%;
}
.wh-form__fieldgroup--groups .wh-form__arrayadd {
  margin-top: 10px;
}
.wh-form__fieldgroup--groups .wh-form__arrayadd::before {
  content: "Extra groep toevoegen";
  color: var(--color-green-dark);
}
.wh-form__fieldgroup--groups.wh-form__fieldgroup--disabled::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.wh-form__fieldgroup--groups.wh-form__fieldgroup--disabled .wh-form__fieldgroup > .wh-form__label {
  color: #666;
}
.wh-form__fieldgroup--groups.wh-form__fieldgroup--disabled .wh-form__arrayadd, .wh-form__fieldgroup--groups.wh-form__fieldgroup--disabled .wh-form__arraydelete {
  opacity: 0.8;
  color: #aaa;
  border-color: #ccc;
  pointer-events: none;
}
.wh-form__fieldgroup--groups.wh-form__fieldgroup--disabled .wh-form__arrayadd::before, .wh-form__fieldgroup--groups.wh-form__fieldgroup--disabled .wh-form__arraydelete::before {
  color: inherit;
}
.wh-form__fieldgroup--groups .wh-form__arrayrow {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
}
.wh-form__fieldgroup--groups .wh-form__arrayrow .wh-form__fieldgroup {
  flex: 1 1 auto;
  margin-left: 20px;
}
.wh-form__fieldgroup--groups .wh-form__arrayrow .wh-form__fieldgroup--small, .wh-form__fieldgroup--groups .wh-form__arrayrow .wh-form__fieldgroup--mbo_level + .wh-form__fieldgroup + .wh-form__fieldgroup {
  flex: none;
  width: calc(33.333% - 20px);
}
.wh-form__fieldgroup--groups .wh-form__arrayrow .wh-form__fieldgroup:not(.wh-form__fieldgroup--small) {
  margin-left: 20px;
  flex-basis: calc(66.666% - 20px);
}
@media (max-width: 600px) {
  .wh-form__fieldgroup--groups .wh-form__arrayrow {
    display: block;
  }
  .wh-form__fieldgroup--groups .wh-form__arrayrow .wh-form__fieldgroup--small, .wh-form__fieldgroup--groups .wh-form__arrayrow .wh-form__fieldgroup--mbo_level + .wh-form__fieldgroup + .wh-form__fieldgroup {
    width: calc(100% - 20px);
  }
}
.wh-form__fieldgroup--groups .wh-form__buttongroup {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 25px;
}