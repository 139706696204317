.gastlessen-dialog {
  background: white;
  padding: 20px;
  max-width: 729px;
  border-radius: 8px;
}
.gastlessen-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gastlessen-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.gastlessen-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.gastlessen-dialog__modalbg {
  z-index: 1001;
}
.gastlessen-dialog__holder {
  transition: transform 300ms, opacity 300ms;
  opacity: 0;
  transform: scale(0.01, 0.01);
  min-width: 250px;
}
.gastlessen-dialog__holder--aftershow {
  opacity: 1;
  transform: scale(1, 1);
}
.gastlessen-dialog__close {
  position: absolute;
  right: 15px;
  top: 15px;
  color: inherit;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-green);
  border-radius: 50%;
  border: 1px solid var(--color-green);
  background-color: #fff;
}
.gastlessen-dialog__close + .gastlessen-dialog > .gastlessen-dialog__content > *:first-child {
  padding-right: 40px;
}
.gastlessen-dialog .wh-form__button, .gastlessen-dialog .button {
  text-align: center;
  margin-right: 0;
}
@media (max-width: 729px) {
  .gastlessen-dialog {
    width: 100%;
  }
}