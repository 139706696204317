.header-top__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.header-top__logowvg, .header-top__logowig {
  display: block;
  flex: none;
  text-decoration: none;
}
.header-top__logowvg {
  height: 108px;
  flex: none;
}
.header-top__logowvg img {
  height: 100%;
  width: auto;
  vertical-align: bottom;
}
.header-top__logowig {
  text-align: right;
  font-weight: 500;
  color: #767676;
  font-size: 14px;
  line-height: 15px;
  padding-bottom: 20px;
}
.header-top__logowig img {
  height: 22px;
  width: auto;
  vertical-align: bottom;
}
.header-top__date {
  font-weight: 700;
  flex: 1 1 auto;
  font-size: 25px;
  line-height: 120%;
  padding: 20px;
}
@media (max-width: 768px) {
  .header-top__logowig {
    display: none;
  }
  .header-top__wrapper {
    justify-content: center;
  }
  .header-top__date {
    flex: none;
    padding: 0 0 12px 15px;
    font-size: 16px;
  }
  .header-top__logowvg {
    height: 60px;
    overflow: hidden;
  }
  .header-top__logowvg > img {
    height: 70px;
    position: relative;
    top: -10px;
  }
}

.header-nav {
  color: #fff;
  position: relative;
  z-index: 1;
}
.header-nav a {
  color: inherit;
  text-decoration: none;
}
.header-nav__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-nav__sitetitle {
  font-weight: 600;
  line-height: 30px;
  padding: 15px 0;
  font-size: clamp(20px, 3.3dvw, 25px);
}
.header-nav__nav {
  align-self: stretch;
}
.header-nav__nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.header-nav__nav__row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}
.header-nav__nav button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}
.header-nav__nav__item {
  position: relative;
  margin-left: 24px;
  display: flex;
}
.header-nav__nav__item:hover .header-nav__nav__item__title, .header-nav__nav__item:focus .header-nav__nav__item__title, .header-nav__nav__item:focus-within .header-nav__nav__item__title {
  text-shadow: 0 0 1px #fff;
}
.header-nav__nav__item__title {
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 20px;
  white-space: nowrap;
  font-family: "TypoPRO Dosis Menu", "TypoPRO Dosis", "Dosis", Arial;
}
.header-nav__nav__item__arrow {
  margin-left: 10px;
}
.header-nav__nav__item__icon {
  margin-left: 20px;
  width: 38px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 17px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  position: relative;
  transition: border-color 200ms;
}
.header-nav__nav__item:hover .header-nav__nav__item__icon, .header-nav__nav__item:focus .header-nav__nav__item__icon, .header-nav__nav__item:focus-within .header-nav__nav__item__icon {
  border: 1px solid #fff;
}
.header-nav__nav__item__toggle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header-nav__nav__item__toggle .header-nav__nav__item__icon::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 14px;
  content: "\f107";
  display: inline-block;
  position: relative;
  top: -6px;
  transition: transform 200ms;
}
.header-nav__nav__item:hover .header-nav__nav__item__toggle .header-nav__nav__item__icon::after, .header-nav__nav__item:focus .header-nav__nav__item__toggle .header-nav__nav__item__icon::after, .header-nav__nav__item:focus-within .header-nav__nav__item__toggle .header-nav__nav__item__icon::after {
  transform: scale(1, -1);
}
.header-nav__nav__item__options {
  position: absolute;
  top: 100%;
  right: -24px;
  left: -24px;
  overflow: hidden;
  max-height: 0;
}
.header-nav__nav__item__options a {
  text-underline-position: under;
  color: var(--color-green-dark);
  display: block;
}
.header-nav__nav__item__options a:hover {
  text-decoration: underline;
}
.header-nav__nav__item:hover .header-nav__nav__item__options, .header-nav__nav__item:focus .header-nav__nav__item__options, .header-nav__nav__item:focus-within .header-nav__nav__item__options {
  max-height: none;
}
.header-nav__nav__subitems {
  padding: 7px 24px !important;
  border: 1px solid var(--color-green);
  border-top: 0 none;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
}
.header-nav__nav__subitems li + li {
  border-top: 1px solid var(--color-green);
}
.header-nav__nav__subitems > li {
  padding: 10px 0;
}
.header-nav__mobiletoggle {
  display: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: relative;
  right: -15px;
  font-size: 22px;
  justify-content: center;
  align-items: center;
}
html.showmobilemenu .header-nav__mobiletoggle__show {
  display: none;
}
.header-nav__mobiletoggle__hide {
  display: none;
  font-size: 26px;
}
html.showmobilemenu .header-nav__mobiletoggle__hide {
  display: inline-block;
}
@media (max-width: 768px) {
  .header-nav__nav {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    width: 320px;
    max-width: 100%;
    background-color: var(--color-green);
  }
  .header-nav__nav > ul {
    padding: 8px 0 0;
    flex-flow: column;
  }
  .header-nav__nav__item {
    margin-left: 0;
    padding: 7px 24px;
    display: block;
  }
  .header-nav__nav__item__toggle {
    pointer-events: none;
    cursor: default;
  }
  .header-nav__nav__item:hover .header-nav__nav__item__toggle .header-nav__nav__item__title, .header-nav__nav__item:focus .header-nav__nav__item__toggle .header-nav__nav__item__title, .header-nav__nav__item:focus-within .header-nav__nav__item__toggle .header-nav__nav__item__title {
    text-shadow: none;
  }
  .header-nav__nav__item__icon, .header-nav__nav__item__arrow {
    display: none;
  }
  .header-nav__nav__item__options {
    overflow: visible;
    position: relative;
    max-height: none;
    background-color: transparent;
    left: 0;
    right: 0;
    top: 0;
  }
  .header-nav__nav__item__options a {
    color: #fff;
    font: 700 18px/130% "TypoPRO Dosis Menu", "TypoPRO Dosis", "Dosis", Arial;
  }
  .header-nav__nav__subitems {
    border: 0 none;
    background-color: transparent;
  }
  html.showmobilemenu .header-nav__nav {
    display: block;
  }
  .header-nav__mobiletoggle {
    display: inline-flex;
  }
}

.header-images {
  position: relative;
  z-index: -1;
}
.header-images__wrapper {
  background-color: #f9f9f9;
  position: relative;
  left: 0;
  right: 0;
  height: 268px;
}
html.dashboardpage .header-images__wrapper, html.classportal_landingpage .header-images__wrapper, html.classportal_registrationpage .header-images__wrapper {
  position: absolute;
}
@media (max-width: 640px) {
  .header-images__wrapper {
    height: 41.875vw;
  }
}
@media (max-width: 320px) {
  .header-images__wrapper {
    height: 134px;
  }
}
.header-images__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 3000ms;
  opacity: 0;
}
.header-images__image--visible {
  z-index: 1;
  opacity: 1;
}